<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex justify-content-space-between align-items marginBottom20">
          <div class="flex align-items">
            <div class="width200 marginRight10">
              <el-input
                v-model="searchData.name"
                placeholder="输入姓名/手机号"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
            <div class="width200 marginRight10">
              <el-input
                v-model="searchData.workerPosition"
                placeholder="输入职位"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
            <div class="width200 marginRight10">
              <el-input
                v-model="searchData.regionName"
                placeholder="输入区域名称"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>

          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
          >新增工作人员</el-button>
        </div>
      </template>
      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          @click="handleDetails(row)"
        >详情</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleEdit(row)"
        >编辑</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
        >删除</el-button>
      </template>

      <!-- 状态挂载 -->
      <template
        slot="workerState"
        slot-scope="{ row }"
      >
        <el-switch
          v-model="row.workerState"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event, row.id)"
        ></el-switch>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible1"
      title="区域列表"
      :visible.sync="dialogVisible1"
      width="800px"
      :before-close="handleClose1"
    >
      <avue-crud
        :data="tableData1"
        :option="option1"
        :table-loading="showLoading1"
      >
      </avue-crud>
    </el-dialog>
  </div>
</template>

<script>
import { whiteList, whiteStateUpdate, deleteWhite } from "@/api/worker/index";
export default {
  name: "index",
  data() {
    return {
      disabled: false,
      searchData: {
        name: "",
        regionName: "",
        workerPosition: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "工作人员列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "姓名",
            prop: "workerUsername",
          },
          {
            label: "手机号",
            prop: "workerPhone",
          },
          {
            label: "所属区域",
            prop: "regionName",
          },
          {
            label: "职位",
            prop: "workerPosition",
          },
          {
            label: "年龄",
            prop: "age",
          },
          {
            label: "性别",
            prop: "sex",
            formatter: function (row, column, cellValue) {
              return row.sex === 1 ? "男" : row.sex === 2 ? "女" : "/";
            },
          },
          {
            label: "状态",
            prop: "workerState",
            formatter: function (row, column, cellValue) {
              return row.workerState === 1 ? "开启" : row.workerState === 2 ? "关闭" : "/";
            },
          },
          {
            label: "创建时间",
            prop: "workerCreateTime",
          },
        ],
      },

      // 弹出表格
      dialogVisible1: false,
      tableData1: [],
      showLoading1: false,
      option1: {
        title: "",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: false, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "区域编号",
            prop: "code",
          },
          {
            label: "区域名称",
            prop: "name",
          },
          {
            label: "状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1 ? "正常" : row.state === 2 ? "冻结" : "/";
            },
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    // 跳转开锁记录
    handleDetails(row) {
      this.$router.push({
        path: "/worker/manage/details",
        query: {
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteWhite({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 白名单状态切换
    handleSwitch(event, id) {
      whiteStateUpdate({
        id: id,
        workerState: event,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem("searchForm");
      this.searchData = {
        name: "",
        regionName: "",
        workerPosition: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      whiteList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 添加
    handleCreate() {
      this.$router.push({
        path: "/worker/manage/create",
        query: {
          type: "create",
        },
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: "/worker/manage/create",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },

    // 表格弹出关闭
    handleClose1() {
      this.dialogVisible1 = false;
    },
  },
};
</script>

<style scoped>
    /deep/.el-table .cell {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 21px!important;
    padding-right: 10px;
}
</style>
